/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

(function($) {
  window.$ = $;
  var $overlay = $('.rg-share-overlay');
  $('body')
    .on('click', '.sharethis', function() {
      if ($($(this).parents().first()[0]).hasClass('entry-summary')) {
        var link = $(this).parents().first().find('.entry-title a'),
            href = link.attr('href'),
            title = link.text(),
            source = 'pagination';
      } else if ($($(this).parents().first()[0]).hasClass('rg-actions')) {
        var href = window.location.href,
            title = $('.entry-title a').text(),
            source = 'page';

            console.log(href, title, source);
      } else {
        var link = $(this).parents().first().find('h2 a'),
            href = link.attr('href'),
            title = link.attr('title'),
            source = 'search';
      }

      $overlay
        .removeClass('hide')
        .attr('data-share-link', href)
        .attr('data-source-click', source)
        .attr('data-title', title);
    })
    .on('click', '.share-container-close', function() {
      $overlay
        .addClass('hide')
        .removeAttr('data-share-link')
        .removeAttr('data-title')
        .removeAttr('data-source-click');
    })
    .on('click', '.rg-share-overlay .social-container a', function() {
      var $ov = $(this).parents('.rg-share-overlay').first(),
          $btn = $(this),
          href = $btn.attr('href'),
          link = $ov.attr('data-share-link'),
          title = $ov.attr('data-title').trim().replace('\n', '');

      if ($ov.attr('data-source-click') == 'pagination') {
        link = 'https:' + link;
      }

      switch(href) {
        case "#twitter":
          if (title.length > 100) {
            title = title.substring(0, 110) + "...";
          }

          window.open('https://twitter.com/intent/tweet?text=' +
            encodeURIComponent(title) + '&url=' +
            encodeURIComponent(link), "_blank", 'height=275,width=450,top=0,left=0'
          );
        break;
        case "#facebook":
          window.open("https://facebook.com/dialog/feed?display=popup&app_id=1654788274772389&link=" +
            encodeURIComponent(link), "_blank", 'height=275,width=450,top=0,left=0'
          );
        break;
        case '#linkedin':
          window.open('https://www.linkedin.com/shareArticle?title=' +
            encodeURIComponent(title) + '&url=' +
            encodeURIComponent(link) + '&mini=true&source=keepingitreal.com',
            "_blank", "height=500,width=500,top=0,left=0"
        );
        break;
        case '#pinterest':
          window.open('http://pinterest.com/pin/create/bookmarklet/?url='+encodeURIComponent(link)+'&is_video=false&description='+encodeURIComponent(title),
             '_blank', 'height=500,width=500,top=0,left=0');
        break;
        case '#googleplus':
          window.open('https://plus.google.com/share?url=' + encodeURIComponent(link), '_blank', 'height=500,width=500,top=0,left=0');
        break;
        case '#email':
          window.open('mailto:?subject=' + encodeURIComponent(title) + '&body=' + encodeURIComponent(link), '_blank');
        break;
      }

      return false;
    });

})(jQuery);
